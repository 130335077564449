//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseLayout from "@/layouts/Base";
export default {
    metaInfo: {
        title: "IIM Rohtak 2024",
    },
    components: {
        BaseLayout
    },
    data() {
        return {
            jury: [
                "Siddharth Diwan (DOP | Qala, Queen)",
                "Aamil Keeyan Khan (Writer | Drishyam 2, Bholaa, Runway 34)",
                "Tapan Basu (DOP | Kahaani 2, Lust Stories 2)",
                "Vipin Sharma (Actor | Guns & Gulaabs, Sirf Ek Banda Kaafi Hai)",
                "Kalpana Nair (Industry Relations | IMDb)",
            ]
        }
    },

}
